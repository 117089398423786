

export class SeasonValueConverter
{

    toView(n)
    {
        console.log('TO VIEW', n);

        if (n === null || n > 26 || n < 0) {
            return '';
        }

        return String.fromCharCode(65 + n);
    }

    fromView(s)
    {
        console.log('FROM VIEW', s, s.charCodeAt(1));

        if (s === null || s === undefined || s === '') {
            return null;
        }

        //Sometimes there is a special char in first place, not sure why
        const code =  s.charCodeAt(s.length - 1) - 65;

        if (code < 0 || code > 26) {
            return null;
        }

        return code;
    }
}
