import {useDrop} from "react-dnd";
import {setEntryField} from "../store/entries-slice";
import React from "react";
import {useDispatch} from "react-redux";
import {useChoiceColumns} from "../store/choices-slice";
import {useDisplayColumns, useHideOccupancyChoices} from "../store/state-slice";
import TableColumns from "./table-columns";

export function NoEntries({group}) {
    const dispatch = useDispatch()
    const choices = useChoiceColumns()
    const hideOccupancyChoices = useHideOccupancyChoices()
    const colSpan = 2 + useDisplayColumns().length

    const [{isOver}, drop] = useDrop(() => ({
        accept: "calculation-entry",
        drop: ({id}) => dispatch(setEntryField(id, "group", group)),
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    }))

    return (
        <tr ref={drop} style={{borderTop: isOver ? "3px solid red" : null}}>
            <td colSpan={colSpan} style={{color: "grey"}} className="border-right">Keine Positionen</td>
            {!hideOccupancyChoices && <td colSpan={choices} className="border-right"/>}
            <TableColumns/>
        </tr>
    )
}
