import {bindable, customElement, inject} from "aurelia-framework";
import * as _ from "lodash";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {UnsavedChangesChecker} from "../../form/unsaved-changes-checker";
import {UnsavedChanges} from "../../form/unsaved-changes-prevent";

@customElement('sio-tourism-calculation-list')
@inject(Client, FlashService, EventAggregator, UnsavedChangesChecker)
export class OldCalculationList {
    @bindable reference;

    calculations = [];

    constructor(client, flash, ea, unsavedChangesChecker) {
        this.client = client;
        this.flash = flash;
        this.ea = ea;
        this.unsavedChangesChecker = unsavedChangesChecker;
    }

    bind() {
        this._loadCalculations();

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if (response.config.modelId !== 'tourism-journey-calculation/calculation') {
                return;
            }

            this._loadCalculations();
        });
    }

    _loadCalculations() {
        const conditions = {
            'reference': {
                '$eq': {
                    id: this.reference.id,
                    modelId: this.reference.modelId
                }
            }
        };
        this.calculations = [];

        this.client.get('tourism-journey-calculation/calculation?conditions=' + JSON.stringify(conditions) + '&embeds[]=reference&sort[0][0]=name&sort[0][1]=ASC').then(data => {
            this.calculations = data.items;

            if (this.selectedCalculation) {
                let found = false;
                _.each(this.calculations, calculation => {
                    if (calculation.id === this.selectedCalculation.id) {
                        this.selectedCalculation = calculation;
                        found = true;
                        return false;
                    }
                });

                if (!found) {
                    this.selectedCalculation = null;
                }
            } else if (this.calculations.length > 0) {
                this.selectedCalculation = this.calculations[0];
                this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
            }
        });
    }

    selectCalculation(calculation) {
        if (!this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges)) {
            return;
        }

        this.selectedCalculation = calculation;
    }
}
