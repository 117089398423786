import {bindable, BindingEngine, customElement, inject} from 'aurelia-framework';
import {Client} from "../api/client";

import "./entry-and-visa.less";
import {UserClient} from "../api/user-client";

@inject(BindingEngine, Client, UserClient)

@customElement('sio-entry-and-visa')
export class EntryAndVisa {

    @bindable country;

    subscriptions = [];
    loading = true;

    nationalityField = {
        label: 'Nationalität des/der Reisenden',
        type: 'choice',
        subType: 'simple',
        set: 'country',
        translateChoiceLabel: false,
        property: 'nationality',
        view: 'form/element/choice-element.html',
        viewModel: 'form/element/form-element',
        value: 'DE'
    };

    residenceSubdivisionField = {
        label: 'Bundesland',
        type: 'choice',
        subType: 'simple',
        translateChoiceLabel: false,
        required: false,
        property: 'residenceSubdivision',
        view: 'form/element/choice-element.html',
        viewModel: 'form/element/form-element',
        choices: [
            {label: "Baden-Württemberg", value: "DE-BW"},
            {label: "Bayern", value: "DE-BY"},
            {label: "Berlin", value: "DE-BE"},
            {label: "Brandenburg", value: "DE-BB"},
            {label: "Bremen", value: "DE-HB"},
            {label: "Hamburg", value: "DE-HH"},
            {label: "Hessen", value: "DE-HE"},
            {label: "Mecklenburg-Vorpommern", value: "DE-MV"},
            {label: "Niedersachsen", value: "DE-NI"},
            {label: "Nordrhein-Westfalen", value: "DE-NW"},
            {label: "Rheinland-Pfalz", value: "DE-RP"},
            {label: "Saarland", value: "DE-SL"},
            {label: "Sachsen", value: "DE-SN"},
            {label: "Sachsen-Anhalt", value: "DE-ST"},
            {label: "Schleswig-Holstein", value: "DE-SH"},
            {label: "Thüringen", value: "DE-TH"},
        ]
    };

    info = {
        entryInformation: false,
        visaInformation: false,
        healthInformation: false
    };

    constructor(be, client, user) {
        this.client = client;
        this.user = user;
        this.subscriptions = [
            be.propertyObserver(this.nationalityField, 'value')
                .subscribe(this.loadData.bind(this)),
            be.propertyObserver(this.residenceSubdivisionField, 'value')
                .subscribe(this.loadData.bind(this))
        ];
    }

    dispose() {
        for (const subscription in this.subscriptions) {
            subscription.dispose();
        }
        this.subscriptions = [];
    }

    bind() {
        this.nationalityField.value = this.user.getCountry();
        this.residenceSubdivisionField.value = null;
        this.loadData(this.user.getCountry());
    }

    loadData() {
        const params = new URLSearchParams();
        const nationality = this.nationalityField.value;

        params.set('countries[]', this.country);
        params.set('nationality', nationality);

        if ('DE' === nationality) {
            const residenceSubdivision = this.residenceSubdivisionField.value;

            if (residenceSubdivision) {
                params.set('residenceSubdivision', residenceSubdivision);
            }
        } else {
            this.residenceSubdivisionField.value = null;
        }

        this.loading = true;
        this.info.entryInformation = false;
        this.info.visaInformation = false;
        this.info.healthInformation = false;
        this.client
            .get('tourism/country/entry-and-visa?' + params.toString())
            .then(info => {
                console.debug('Loaded entry and visa info', info);
                if (info && info.hasOwnProperty(this.country)) {
                    this.info.entryInformation = info[this.country].entryInformation;
                    this.info.visaInformation = info[this.country].visaInformation;
                    this.info.healthInformation = info[this.country].healthInformation;
                    this.info.durationWithoutVisa = info[this.country].durationWithoutVisa;
                    this.info.passport = info[this.country].passport;
                    this.info.passportValidity = info[this.country].passportValidity;
                    this.info.passportValidityType = info[this.country].passportValidityType;
                    this.info.updatedAt = info[this.country].updatedAt;
                }
            })
            .finally(() => this.loading = false);
    }
}
