import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDate, removeDate, selectDateIds, setDate, useDate, useSeason, useSeasons} from "../store/dates-slice";
import {Button, Icon, IconButton, Input, InputGroup, Tooltip, Whisper} from "rsuite";
import {selectChoiceIdsWithFlights, selectChoiceLabels, selectChoicesTotal} from "../store/choices-slice";
import DebouncedInput from "./debounced-input";
import {useField} from "../store/fields-slice";
import CalculationContext from "../context";
import {setPrice, useDatePrice} from "../store/dates-prices-slice";
import PriceDisplay from "./price-display";
import {useLoading} from "../store/state-slice";
import cs from "classnames";
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon";

function setSizes() {
    const element = document.querySelector(".calculation-dates-table > thead > tr:first-child");

    if (!element) {
        return;
    }

    const height = element.offsetHeight
    document.querySelectorAll(".calculation-dates-table > thead > tr:last-child > th").forEach(element => {
        element.style.top = height + "px"
    })

    const left = document.getElementById('navbar').offsetWidth + "px"
    document.querySelectorAll(
        ".calculation-dates-table tr:first-child > th:nth-child(2), " +
        ".calculation-dates-table > tbody > tr > td:nth-child(2)"
    ).forEach(element => {
        element.style.left = left
        element.style.position = "sticky"
    })
}

export default function Dates() {
    const dispatch = useDispatch()
    const dateIds = useSelector(selectDateIds)
    const choices = useSelector(selectChoicesTotal)
    const fromDate = useField("fromDate")
    const updating = useLoading()

    useLayoutEffect(() => {
        setSizes()
    }, [updating])

    useEffect(() => {
        window.addEventListener('resize', setSizes)

        return () => {
            window.removeEventListener('resize', setSizes)
        }
    }, [])

    return (
        <div className="col-xs-12">
            <Seasons/>
            <div className="table-responsive">
                <table className="table table-striped table-hover calculation-dates-table">
                    <DatesHead/>

                    <tbody>
                        {dateIds.map(id => (
                            <DatesRow key={id} id={id}/>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan={3 + choices * 3}>
                                <IconButton icon={<SioIcon icon=" fa fa-plus"/>} onClick={onClick} disabled={updating}>
                                    Hinzufügen
                                </IconButton>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )

    function onClick() {
        dispatch(addDate(fromDate))
    }
}

function Seasons() {
    const {dateConverter} = useContext(CalculationContext)
    const seasons = useSeasons()

    if (!seasons.length) {
        return null
    }

    return (
        <dl className="seasons">
            {seasons.map(({from, to, label, color}) => (
                <React.Fragment key={from}>
                    <dt style={{backgroundColor: color, borderColor: color}}>{label}</dt>
                    <dd>{dateConverter.range(from, to)}</dd>
                </React.Fragment>
            ))}
        </dl>
    )
}

function DatesHead() {
    const choiceLabels = useSelector(selectChoiceLabels) ?? {}

    return (
        <thead>
            <tr>
                <th rowSpan="2">Saison</th>
                <th rowSpan="2">Abreise</th>
                <th rowSpan="2" className="border-right">Rückreise</th>
                {Object.entries(choiceLabels).map(([id, label]) => (
                    <th key={id} className="text-center price border-right" colSpan="4">{label}</th>
                ))}
                <th rowSpan="2"/>
            </tr>
            <tr>
                {Object.keys(choiceLabels).map(id => (
                    <React.Fragment key={id}>
                        <th className="text-right price">EK</th>
                        <th className="text-right price">Marge</th>
                        <th className="text-right price">VK</th>
                        <th className="border-right"></th>
                    </React.Fragment>
                ))}
            </tr>
        </thead>
    )
}

function DatesRow({id}) {
    const dispatch = useDispatch()
    const {dateConverter} = useContext(CalculationContext)
    const choiceIds = useSelector(selectChoiceIdsWithFlights)
    const {end, season: seasonId} = useDate(id) ?? {}
    const loading = useLoading()
    const season = useSeason(seasonId)

    return (
        <tr>
            <td>
                {season && (
                    <span className="season"
                          style={{backgroundColor: season.color, borderColor: season.color}}
                    >{season.label}</span>
                )}
            </td>
            <DateCell id={id}/>
            <td className="border-right price">{end && dateConverter.toView(end, "dd L")}</td>
            {choiceIds.map(choiceId => (
                <DatePrice key={choiceId} id={id} choiceId={choiceId}/>
            ))}
            <td>
                <Button size="sm" disabled={loading} onClick={onClick}>
                    <i className="fa fa-trash"/>
                </Button>
            </td>
        </tr>
    )

    function onClick() {
        dispatch(removeDate(id))
    }
}

function DateCell({id}) {
    const dispatch = useDispatch()
    const date = useDate(id).date
    const fromDate = useField("fromDate")
    const toDate = useField("toDate")

    return (
        <td>
            <DebouncedInput
                throttle={1000}
                id={id}
                name={id}
                className="form-control"
                value={date}
                type="date"
                min={fromDate}
                max={toDate}
                onChange={(value) => {
                    if (value == null || value == '' || isNaN(Date.parse(value))) {
                        return;
                    }

                    dispatch(setDate(id, value))
                }}
            />
        </td>
    )
}

function DateWarning({warning}) {
    const {currencyConverter} = useContext(CalculationContext)
    const [days, {amount, currency}] = warning
    let tooltip = (1 === days ? "Einen Tag" : days + " Tage") + " später ist die Reise "

    if (amount < 0) {
        tooltip += currencyConverter.toView({amount: -1 * amount, currency}) + " billiger"
    } else {
        tooltip += currencyConverter.toView({amount, currency}) + " teurer"
    }

    tooltip += " im Einkauf"

    return (
        <Whisper trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
            <span className="fa fa-circle-exclamation"/>
        </Whisper>
    )
}

function DatePrice({id, choiceId}) {
    const dispatch = useDispatch()
    const {currencyConverter} = useContext(CalculationContext)
    const {warning} = useDate(id)
    const {lowest, highest, defined, purchase, margin, price} = useDatePrice(id, choiceId) ?? {}
    const currency = useField("currency")
    const updating = useLoading()
    const [editing, setEditing] = useState(false)
    const [editPrice, setEditPrice] = useState("")

    return editing ? (
        <td className="border-right" colSpan={4} style={{minWidth: "6rem"}}>
            <InputGroup>
                <Input type="number" min="0" step="0.01" className="text-right"
                       placeholder={defined ? .01 * defined : ""}
                       value={editPrice} onChange={value => setEditPrice(value)}/>
                <InputGroup.Button size="xs" onClick={() => save()}>
                    <SioIcon icon="fa fa-circle-check"/>
                </InputGroup.Button>
                <InputGroup.Button size="xs" onClick={() => setEditing(false)}>
                    <SioIcon icon="fa fa-circle-xmark"/>
                </InputGroup.Button>
            </InputGroup>
        </td>
    ) : (
        <>
            <td className="text-right price">
                {purchase && (
                    <PriceDisplay money={{amount: purchase, currency}} updating={updating}
                                  currencyConverter={currencyConverter}/>
                )}
            </td>
            <td className="text-right price">
                {margin && (
                    <PriceDisplay money={{amount: margin, currency}} updating={updating}
                                  currencyConverter={currencyConverter}/>
                )}
            </td>
            <td className={cs("text-right price", {
                "price-lowest": lowest,
                "price-highest": highest
            })}>
                {defined && (
                    <span className={warning ? "warning" : ""}>
                        <PriceDisplay
                            money={{amount: price ?? defined, currency}}
                            updating={updating}
                            currencyConverter={currencyConverter}
                        />
                        {warning && <DateWarning warning={warning}/>}
                    </span>
                )}
            </td>
            <td className="border-right">
                <Button size="xs" appearance="link" disabled={updating} onClick={() => edit()}>
                    <i className="fa fa-pencil"/>
                </Button>
            </td>
        </>
    )

    function edit() {
        const number = parseInt(price)
        setEditPrice(0 < number ? "" + (.01 * number) : "")
        setEditing(true)
    }

    function save() {
        const number = parseFloat(editPrice)
        dispatch(setPrice(id, choiceId, 0 < number ? Math.floor(100 * editPrice) : null))
        setEditing(false)
    }
}
