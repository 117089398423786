import React from "react"
import {useDebouncedChange} from "../../../utilities/debounce";

export default function DebouncedInput({throttle = 300, onChange, ...props}) {
    const [value, setValue] = useDebouncedChange(props.value, throttle, onChange)

    return (
        <input {...props} value={value} onChange={event => setValue(event.target.value)}/>
    )
}
