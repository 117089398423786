import {bindable, customElement} from 'aurelia-framework';

import "./sabre-gmr-search-results.less";

@customElement('sabre-gmr-search-results')
export class SabreGmrSearchResults {
    @bindable searchResult;

    @bindable add;
    @bindable select;
}
