import React from "react";
import {selectChoiceIdsWithFlights} from "../store/choices-slice";
import {useSelector} from "react-redux";
import CalculationTableFooterPrices from "./table-footer-prices";

export default function CalculationTableFooter({columnCount}) {
    const choiceIds = useSelector(selectChoiceIdsWithFlights)
    return (
        <tfoot>
            {choiceIds.map(id => (
                <CalculationTableFooterPrices key={id} choiceId={id} columnCount={columnCount}/>
            ))}
        </tfoot>
    )
}
