import {bindable, observable, customElement} from 'aurelia-framework';

import "./amadeus-pnr-search-filters.less";

@customElement('amadeus-pnr-search-filters')
export class AmadeusPnrSearchFilters {
    @bindable params = {};
    @bindable error;

    recordLocator;
    @observable departureDate;
    lastName;
    companyId;
    flightNumber;

    paramsChanged() {
        // Update only recordLocator, we don't want to erase already filled-in values from fields
        //  triggered on select() call when user selects a PNR from list of search results.

        this.recordLocator = this.params.recordLocator;
    }

    valueChanged() {
        // this.params serves as output for filter values
        // the inner state of filters is saved inside this.recordLocator, this.lastName and so on.
        //
        // This is done in order to not erase lastName/companyId filter values when recordLocator is being entered,
        //  and still allow search API call with only recordLocator instead of all fields together.

        if (this.recordLocator) {
            this.params.recordLocator = this.recordLocator;

            delete this.params.departureDate;
            delete this.params.lastName;
            delete this.params.companyId;
            delete this.params.flightNumber;
        } else {
            delete this.params.recordLocator;

            this.params.departureDate = this.departureDate;
            this.params.lastName = this.lastName;
            this.params.companyId = this.companyId;
            this.params.flightNumber = this.flightNumber;
        }

        if (!this.params.lastName && !this.params.recordLocator) {
            this.error = 'PNR-Kennung oder Nachname müssen eingegeben werden.';
        }
    }

    departureDateChanged() {
        // Implemented via @observable, because sio-date-input doesn't allow attaching "change.trigger"

        this.valueChanged();
    }
}
