import React, {useEffect} from "react";
import {bindable, customElement, inject, noView} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {Provider, useDispatch} from "react-redux";
import store from "../../store/store";
import CalculationContext from "./context";

import "./calculation.less";
import {FlashService} from "../../flash/flash-service";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import {DateValueConverter, PercentageValueConverter} from "../../converters";
import {Calculations} from "./components/calculations";
import Calculation from "./components/calculation";
import {ViewActionHandler} from "../../action/handlers/view.action-handler";
import {UrlUtils} from "../../utilities/url-utils";
import {useOrganizationQuery} from "../../store/api";
import {Loader} from "rsuite";
import {calculationSettingsLoaded} from "./store/calculation-settings-loaded";
import {ConfigurationLoader} from "../../form/loader/configuration-loader";
import {StandardActions} from "../../action/standard-actions";

@inject(
    Element,
    EventAggregator,
    DialogService,
    I18N,
    FlashService,
    CurrencyValueConverter,
    PercentageValueConverter,
    DateValueConverter,
    ViewActionHandler,
    UrlUtils,
    ConfigurationLoader,
    StandardActions
)
@customElement("tourism-journey-calculation")
@noView()
export class AureliaElement {

    @bindable itinerary;

    constructor(
        element,
        ea,
        dialog,
        i18n,
        flash,
        currencyConverter,
        percentageConverter,
        dateConverter,
        viewActionHandler,
        urlUtils,
        formConfigLoader,
        standardActions
    ) {
        this.element = element;
        this.ea = ea;
        this.dialog = dialog;
        this.i18n = i18n;
        this.flash = flash;
        this.currencyConverter = currencyConverter;
        this.percentageConverter = percentageConverter;
        this.dateConverter = dateConverter;
        this.viewActionHandler = viewActionHandler;
        this.urlUtils = urlUtils;
        this.formConfigLoader = formConfigLoader;
        this.standardActions = standardActions;
    }

    itineraryChanged() {
        ReactDOM.unmountComponentAtNode(this.element);
        this.render();
    }

    detached() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        if (!this.itinerary) {
            return;
        }

        const value = {
            itinerary: this.itinerary.id,
            organization: this.itinerary.organization.id,
            hasMarginTax: !!this.itinerary.hasMarginTax,
            title: this.itinerary.title?.de ?? '',
            i18n: this.i18n,
            dialog: this.dialog,
            formConfigLoader: this.formConfigLoader,
            flash: this.flash,
            currencyConverter: this.currencyConverter,
            percentageConverter: this.percentageConverter,
            dateConverter: this.dateConverter,
            viewActionHandler: this.viewActionHandler,
            urlUtils: this.urlUtils,
            standardActions: this.standardActions
        }

        ReactDOM.render(
            <CalculationContext.Provider value={value}>
                <Provider store={store}>
                    <Initializer organization={value.organization}>
                        <Calculations/>
                        <Calculation/>
                    </Initializer>
                </Provider>
            </CalculationContext.Provider>,
            this.element
        )
    }
}

function Initializer({organization, children}) {
    const dispatch = useDispatch()
    const {isLoading, data} = useOrganizationQuery(organization)

    useEffect(
        () => {
            if (data?.settings) {
                dispatch(calculationSettingsLoaded(data.settings))
            }
        },
        [isLoading]
    )

    return isLoading ? <Loader/> : children
}
