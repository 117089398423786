import React from "react"
import {useHeader} from "../store/state-slice";

export default function TableColumns() {
    const columns = useHeader()[0]

    return columns.map(([from, to, colSpan]) => (
        <td key={from + to} colSpan={colSpan} className="border-right"/>
    ))
}
