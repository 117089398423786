import React, { useContext} from "react";
import { useDispatch } from "react-redux";
import { Placeholder } from "rsuite";
import CalculationContext from "../context";
import Help from "./help";
import { setField, useField } from "../store/fields-slice";
import { useOrganizationQuery } from "../../../store/api";
import DebouncedInput from "./debounced-input";
import { setExchangeRate, useCurrencies } from "../store/entries-slice";
import { useCalculationDetails } from "../store/calculation-details";
import numeral from "numeral";
import DurationCollection from "./duration-collection";

export default function Parameters() {
    const dispatch = useDispatch();
    const { i18n, organization } = useContext(CalculationContext);
    const { isLoading, data } = useOrganizationQuery(organization);
    const { isFetching } = useCalculationDetails();
    const dynamic = useField("dynamic");
    const currencies = useCurrencies();

    if (isLoading) {
        return <Placeholder.Grid rows={1} active />;
    }

    const { displayFields, allowExchangeRateOverride } =
        data?.settings?.tourismJourneyCalculationSettings ?? {};

    const fields = [
        {
            field: "dateRanges",
            help: "tourism-journey-calculation.date-range-help",
            type: "collection",
        },
        {
            field: "marginParticipants",
            help: "tourism-journey-calculation.marginParticipants-help",
            type: "number",
            step: "1",
        },
    ];

    if (displayFields?.includes("pax")) {
        fields.push({
            field: "participantsRange",
            help: "tourism-journey-calculation.participants-range-help",
            type: "text",
        });
    }

    if (displayFields?.includes("daysCount")) {
        fields.push({
            field: "daysCount",
            type: "number",
            step: "1",
            min: "1",
        });
    }

    if (allowExchangeRateOverride) {
        for (const [currency, defaultValue] of currencies) {
            fields.push({
                currency,
                defaultValue,
                type: "number",
                step: "0.01",
            });
        }
    }

    return (
        <div className="row" style={{ maxWidth: "95vw" }}>
            {fields.map(({ field, currency, ...props }) =>
                currency ? (
                    <Field
                        key={currency}
                        currency={currency}
                        i18n={i18n}
                        disabled={isFetching}
                        {...props}
                    />
                ) : field == "dateRanges" ? (
                    <DurationCollection
                        key={field}
                        field={field}
                        i18n={i18n}
                        disabled={isFetching}
                        {...props}
                    />
                ) : (
                    <Field
                        key={field}
                        field={field}
                        i18n={i18n}
                        disabled={isFetching}
                        {...props}
                    />
                )
            )}

            {displayFields?.includes("dynamic") && (
                <div className="col-xs-12 col-sm-6 col-md-3">
                    <div className="checkbox">
                        <label>
                            <input
                                type="checkbox"
                                checked={dynamic}
                                onChange={changeDynamic}
                                disabled={isFetching}
                            />
                            {i18n.tr("tourism-journey-calculation.dynamic") +
                                " "}
                            <Help>
                                {i18n.tr(
                                    "tourism-journey-calculation.dynamicHelp"
                                )}
                            </Help>
                        </label>
                    </div>
                </div>
            )}
        </div>
    );

    function changeDynamic() {
        dispatch(setField("dynamic", !dynamic));
    }
}


function Field({
    label,
    help,
    field,
    currency,
    column,
    required,
    i18n,
    entries,
    type,
    defaultValue,
    ...props
}) {
    const value = currency ? defaultValue : useField(field);
    const dispatch = useDispatch();

    return (
        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="form-group">
                <div>
                    <label
                        htmlFor={field ?? currency}
                        className={required ? "required" : null}
                    >
                        {currency
                            ? currency +
                              " überschreiben (" +
                              numeral(parseFloat(defaultValue)).format("0.00") +
                              ")"
                            : i18n.tr(
                                  label ??
                                      "tourism-journey-calculation." + field
                              )}
                    </label>
                    {help && (
                        <>
                            {" "}
                            <Help>{i18n.tr(help)}</Help>
                        </>
                    )}
                </div>
                <div>
                    <DebouncedInput
                        throttle={"date" === type ? 1000 : 300}
                        id={field ?? currency}
                        name={field ?? currency}
                        className="form-control"
                        type={type}
                        value={value}
                        {...props}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    );

    function onChange(value) {
        dispatch(
            field ? setField(field, value) : setExchangeRate(currency, value)
        );
    }
}
