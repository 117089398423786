import {customElement, bindable} from "aurelia-framework";

@customElement('sio-calculated-result')
export class CalculatedResult
{
    @bindable value;
    @bindable loading;

    @bindable redMargin;
    @bindable yellowMargin;

    @bindable type = 'currency';

    getClass(value, redMargin, yellowMargin)
    {
        if (!value || !redMargin || !yellowMargin) {
            return '';
        }

        if (value < redMargin) {
            return 'text-danger';
        }

        if (value < yellowMargin) {
            return 'text-warning';
        }

        return 'text-success';
    }
}
