import {bindable, customElement} from 'aurelia-framework';

import "./amadeus-search-results.less";

@customElement('amadeus-search-results')
export class AmadeusSearchResults {
    @bindable searchResult;

    @bindable add;
    @bindable select;
}
