import {customElement, bindable} from 'aurelia-framework';

@customElement('tourism-calculation-percentage-display')
export class PercentageDisplay
{
    @bindable value;

    valueChanged()
    {
        if (isNaN(this.value)) {
            this.nan = true;
        } else {
            this.nan = false;
            this._value = this.value;
        }
    }
}