import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";

@inject(DialogController)
export class AddServiceDialog {

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    canActivate(context)
    {

    }

    addService(object)
    {
        this.dialogController.ok(object);

        return Promise.resolve();
    }
}
