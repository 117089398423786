import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {ModuleConfigClient} from "../../api/module-config-client";

import "./calculation.less";

@inject(Client, ModuleConfigClient)
@customElement('tourism-hotel-calculation-overview')
export class Calculation {

    @bindable itinerary;

    overview;
    seasons = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    loading = false;

    constructor(client, moduleConfigClient) {
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
    }

    async bind() {
        this.calculation = null;
        this.loading = true;

        try {
            this.overview = await this.client.get('tourism-hotel-calculation/overview/' + this.itinerary.id)
            this.additionalServicesPanel = (await this.moduleConfigClient.getTabs(this.itinerary.modelId) ?? [])
                .find(({id}) => "services" === id)
                ?.preset?.panels
                ?.find(({id}) => "additional-services" === id)
        } catch (error) {
            console.error(error)
        }

        this.loading = false;
    }
}
