import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {FormServiceFactory} from "../../form/service/form-service-factory";

@inject(
    DialogController,
    FormServiceFactory
)
export class EditEquipmentDialog {
    constructor(
        dialogController,
        formServiceFactory
    ) {
        this.dialogController = dialogController;
        this.formServiceFactory = formServiceFactory;
    }

    activate(context)
    {
        this.formService = this.formServiceFactory.getFormService(
            {
                id: 'edit-tourism-equipment',
                fields: [{
                    label: 'tourism-journey-equipment.remark',
                    property: 'remark',
                    type: 'text',
                    subType: 'simple',
                    required: false
                }]
            },
            context.equipment.originalEntity,
            null
        );

        this.equipment = context.equipment;
    }

    save()
    {
        const output = this.formService.getValue();
        this.dialogController.ok(output);
    }
}
