import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import "./seat-map-assignment.less"
import Sortable from "sortablejs";
import * as _ from "lodash";
import {BindingSignaler} from "aurelia-templating-resources";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";

@customElement('sio-tourism-seat-map-assignment')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler)
export class SeatMapAssignment {

    @bindable object;
    decks = [];
    participants = [];
    assignments = [];
    choices = [];
    rows = [];
    allowedAssignments = {};
    loading = false;

    constructor(client, i18n, flash, ea, signaler) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
    }

    async attached() {
        this.viewReady = true;

        this._initializeSorting();

    }

    detached() {
        // this.subscriber.dispose();
        this.setChanged(false);
        this.viewReady = false;
    }

    async objectChanged() {
        this.loading = true;
        await this.fetchAssignments();

        this._initializeSorting();

        this.loading = false;
    }

    async fetchAssignments() {

        let assignments = await this.client.get('tourism-seat-map/assignments/' + this.object.id);

        await this.processAssignments(assignments.assignments);

        await this.processRows(assignments);

        await this.processAllowedAssignments(assignments);

        this.signaler.signal("tourism-seat-map-fetched-assignments");

    }

    async processRows(assignments) {
        this.rows = assignments.rows.map(row => {
            row.seats.map(seat => {
                seat.isSeatReserved = this.getAssignmentBySeatIdentifier(row.id + '-' + seat.id)?.isSeatReserved ?? false;
                return seat;
            })
            return row;
        });
    }

    async processAllowedAssignments(assignments) {
        this.allowedAssignments = assignments.allowedAssignments;
    }

    async processAssignments(assignments) {
        assignments.forEach((assignment) => {
            let rowId = assignment?.assignment?.rowId ?? null;
            let seatId = assignment?.assignment?.seatId ?? null;

            assignment.assignmentIdentifier = assignment.participant.id;
            assignment.seatIdentifier = null;

            if (rowId !== null && seatId !== null) {
                assignment.seatIdentifier = rowId + '-' + seatId;
            }

            if (assignment?.assignment?.reservedSeat) {
                assignment.isSeatReserved = true;
            } else {
                assignment.isSeatReserved = false;
            }

        });

        this.assignments = assignments;
    }

    isAssigned(assignment) {
        return null !== assignment.identifier;
    }

    getUnassigned() {
        let addedParticipantIds = [];
        let unassigned = [];

        this.assignments.forEach((assignment) => {
            if (assignment.seatIdentifier !== null || addedParticipantIds.includes(assignment?.participant?.id)) {
                return;
            }

            addedParticipantIds.push(assignment?.participant?.id);
            unassigned.push(assignment);
        });

        addedParticipantIds = null;

        return unassigned;
    }

    getRows() {
        return this.rows;
    }

    _getSeatType(type) {
        return type ?? 'hidden';
    }

    _initializeSorting() {

        if (this.object.isLocked) {
            return;
        }

        if (!this.viewReady || this.sortingInitialized) {
            return;
        }

        let element = document.getElementById('unassigned');

        if (!element) {
            return;
        }

        this._initializeAssignment('unassigned');

        this.rows.forEach((row) => {
            row.seats.forEach((seat) => {
                if (!seat.isSeatReserved) {
                    this._initializeAssignment('seat-' + row.id + '-' + seat.id);
                }
            })
        });

        this.sortingInitialized = true;

    }

    _initializeAssignment(identifier) {

        Sortable.create(document.getElementById(identifier), {
            group: 'assignment',
            forceFallback: true,
            onMove: (evt) => {

                if (evt.from.id !== evt.to.id) {

                    if (!this._canSort(evt.to, evt.dragged)) {
                        return false;
                    }

                }
            },
            onSort: (evt) => {

                let assignment = this._assignmentByAssignment(evt.item);


                assignment.seatIdentifier = evt?.to?.dataset?.seatIdentifier ?? null;

                this.signaler.signal('moved');
                this.setChanged(true);

            }
        });
    }

    _assignmentByAssignment(evt) {

        let identifier = evt?.dataset?.assignmentIdentifier ?? null;

        return this._assignmentByAssignmentIdentifier(identifier);
    }

    _assignmentByAssignmentIdentifier(identifier) {

        if (null === identifier) {
            return null;
        }

        return _.find(this.assignments, (assignment) => {
            return assignment.assignmentIdentifier === identifier;
        });
    }

    _assignmentBySeat(evt) {

        let identifier = evt?.dataset?.seatIdentifier ?? null;

        if (null === identifier) {
            return null;
        }

        return this.getAssignmentBySeatIdentifier(identifier)
    }

    getAssignmentBySeatIdentifier(identifier) {
        return _.find(this.assignments, (assignment) => {
            return assignment.seatIdentifier === identifier;
        });
    }

    _canSort(to, item) {

        if (to?.id === 'unassigned') {
            return true;
        }

        let assignedSeat = this._assignmentBySeat(to);

        if (assignedSeat) {
            return false;
        }

        let rowSeat = this._rowSeatByIdentifier(to);

        if ((rowSeat?.seat?.type ?? null) === null || rowSeat?.seat?.type === 'blocked') {
            return false;
        }

        let assignment = this._assignmentByAssignment(item)

        if (this.allowedAssignments?.[assignment?.participant?.customer?.modelId] == null) {
            return true;
        }

        let allowed = this.allowedAssignments?.[assignment?.participant?.customer?.modelId] ?? [];

        if (!allowed.includes(rowSeat?.seat?.type)) {
            return false;
        }

        return true;
    }

    _rowSeatByIdentifier(evt) {

        let identifier = evt?.dataset?.seatIdentifier ?? null;

        if (identifier === null) {
            return null;
        }

        //seat-rowId-seatId
        let parts = identifier.split('-');

        return this.getRowSeat(parts[0], parts[1])
    }

    getRowSeat(rowId, seatId) {
        let row = _.find(this.object.rows, (row) => {
            return row.id === rowId;
        });

        let seat = _.find(row.seats, (seat) => {
            return seat.id === seatId;
        });

        return {
            row: row,
            seat: seat
        };
    }

    save() {

        let updateAssignments = [];

        this.assignments.forEach((assignment) => {

            let seatAssignment = {};

            let parts = assignment?.seatIdentifier?.split('-');

            if (parts && parts[0] && parts[1]) {
                seatAssignment.rowId = parts[0];
                seatAssignment.seatId = parts[1];
            }

            seatAssignment.reservedSeat = assignment.isSeatReserved;

            updateAssignments.push({
                orderItem: {
                    id: assignment.orderItem.id,
                    modelId: assignment.orderItem.modelId
                },

                participant: {
                    id: assignment.participant.id,
                    modelId: assignment.participant.modelId
                },

                assignment: seatAssignment
            });
        });

        return this.client.put('tourism-seat-map/assignments/' + this.object.id, {assignments: updateAssignments}).then(data => {

            // this.ea.publish('sio_form_post_submit', {config: {modelId: 'tourism-seat-map/seat-map'}});

            this.flash.success('form.success');
            this.setChanged(false);

            return data;
        });

    }

    setChanged(changed) {
        if (changed) {
            if (null != this.changesKey) {
                return; // No need to notify several times
            }

            this.changesKey = 'tourism-seat-map/seat-map__' + new Date();
            this.ea.publish('sio_register_unsaved_changes', {changesKey: this.changesKey});
        } else {

            this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
            this.changesKey = null;
        }
    }

}
