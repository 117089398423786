import React, {useState} from "react"
import {Button, IconButton} from "rsuite";
import {Container} from "aurelia-dependency-injection";
import {DialogService} from "aurelia-dialog";
import {DialogView} from "../../../view/dialog-view";

export default function Action({handler, context, config, icon, children, onResult, onError, ...props}) {
    const [loading, setLoading] = useState(false)
    const ButtonComponent = icon ? IconButton : Button

    return (
        <ButtonComponent icon={icon} loading={loading} {...props} onClick={onClick}>
            {children}
        </ButtonComponent>
    )

    async function onClick() {
        setLoading(true)

        try {
            if (config) {
                context = handler.getReturnContext(config, context)
            }
            const result = await handler.getAction(context)()
            onResult && onResult(result)
        } catch (e) {
            onError && onError(e)
        }

        setLoading(false)
    }
}


const dialogService = Container.instance.get(DialogService)
const dialogHandler = {
    getAction: model => () => dialogService
        .open({viewModel: DialogView, model})
        .whenClosed(({wasCancelled, output}) => wasCancelled ?
            Promise.reject() : Promise.resolve(output))
}

export const DialogAction = props => (
    <Action handler={dialogHandler} {...props}/>
)
