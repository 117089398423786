import {bindable, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import React from "react";
import VacancyDisplay from "./vacancy-display";
import {Client} from "../../api/client";
import {I18N} from "aurelia-i18n";

@noView
@inject(Element, Client, I18N)
@customElement("vacancy-display-aurelia")
export default class VacancyDisplayAurelia {
    @bindable() data;

    client = null;

    localeMap = {
        de: 'de-de',
        en: 'en-us',
    };

    constructor(container, client, i18n) {
        this.container = container;
        this.client = client;
        this.i18n = i18n;
    }

    attached() {
        this.render();
    }

    dataChanged() {
        this.detached();

        this.attached();
    }

    async render() {

        const locale = this.i18n.getLocale();

        render(<VacancyDisplay locale={this.localeMap[locale] ?? 'en-us'} {...this.data}/>, this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
