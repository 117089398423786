import {combineReducers} from "@reduxjs/toolkit"
import fields from "./fields-slice"
import entries from "./entries-slice"
import choices from "./choices-slice"
import choicesPrices from "./choices-prices-slice"
import dates from "./dates-slice"
import datesPrices from "./dates-prices-slice"
import state from "./state-slice"

export const calculation = combineReducers({
    fields, entries, choices, choicesPrices, dates, datesPrices, state
})
export default calculation
