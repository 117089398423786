import React, {useContext, useEffect, useState} from "react";
import PanelHeader from "./panel-header";
import PanelBody from "./panel-body";
import {clearFields, useField} from "../store/fields-slice";
import CalculationContext from "../context";
import {useDispatch} from "react-redux";

export default function Calculation() {
    const dispatch = useDispatch()
    const {itinerary} = useContext(CalculationContext)
    const id = useField("id")
    const stateItinerary = useField("itinerary")
    const [stateId, setStateId] = useState(id)

    useEffect(() => () => {
        dispatch(clearFields({}))
    }, [])

    useEffect(
        () => {
            if (itinerary !== stateItinerary) {
                dispatch(clearFields({}))
            }
        },
        [itinerary]
    )

    useEffect(
        () => {
            setStateId(id)
        }, [id]
    )

    if (!id || "" === id || id !== stateId || itinerary !== stateItinerary) {
        return null
    }

    return (
        <div className="panel">
            <PanelHeader/>
            <PanelBody/>
        </div>
    )
}
