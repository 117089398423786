import {createAction} from "@reduxjs/toolkit"

export const calculationSettingsLoaded = createAction(
    "calculation/settings-loaded",
    function prepare({tourismJourneyCalculationSettings: settings}) {
        settings = {...settings}
        settings.displayFields = [...settings.displayFields]
        settings.displayColumns = [...settings.displayColumns]
        settings.allowedServices = [...settings.allowedServices]
        settings.groups = [...settings.groups]

        const index = settings.displayColumns.indexOf("additionalService")
        if (-1 !== index) {
            settings.groups.push("additionalService")
            settings.displayColumns.splice(index, 1)
        }

        settings.colSpan = 2 + settings.displayColumns.length

        return {payload: settings}
    }
)
