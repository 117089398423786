import React, {useContext, useEffect} from "react";
import {Button} from "rsuite";
import CalculationContext from "../context";
import {useCalculationSaveMutation} from "../../../store/api";
import {useSelector} from "react-redux";
import {useField} from "../store/fields-slice";
import {selectEntryTotal} from "../store/entries-slice";
import {useAutoSave, useDirty, useDoAutoSave, useLoading} from "../store/state-slice";
import {selectAllChoices} from "../store/choices-slice";
import {makeId, selectAllChoicesPrices, selectChoicesPricesColumns} from "../store/choices-prices-slice";
import {selectCalculationSaveParams} from "../store/calculation-save";
import {processFlightOverride} from "../store/flight-override";

export function SaveButton({header}) {
    const {i18n, flash} = useContext(CalculationContext)
    const isFetching = useLoading()
    const autoSave = useAutoSave()
    const doAutoSave = useDoAutoSave()
    const [save, {isLoading}] = useCalculationSaveMutation()
    const saveParams = useSelector(selectCalculationSaveParams)
    const active = useField("active")
    const dirty = useDirty()
    const choices = useSelector(selectAllChoices)
    const choicesPrices = useSelector(selectAllChoicesPrices)
    const choicesPricesColumns = useSelector(selectChoicesPricesColumns)
    const noteNew = useField('note')

    useEffect(
        () => {
            if (autoSave && doAutoSave && header && dirty && !isLoading) {
                onClick()
            }
        }, [doAutoSave]
    )

    return (
        <Button color="red" loading={isLoading} onClick={onClick} disabled={isFetching}>
            {i18n.tr("sio.save")}
        </Button>
    )

    function onClick() {
        // if (!saveParams.entries.length) {
        //     flash.warning("Diese Kalkulation enthält keine Leistungen und kann daher nicht gespeichert werden.")
        //     return
        // }

        const dates = new Set
        for (const {date} of saveParams.dates) {
            if (dates.has(date)) {
                flash.warning("Diese Kalkulation enthält doppelte Termine und kann daher nicht gespeichert werden.")
                return
            }
            dates.add(date)
        }

        const params = {
            ...saveParams,
            occupancyChoices: [].concat(saveParams.occupancyChoices),
            note:noteNew
        }

        for (const [choiceId, groupId, paxId] of choicesPricesColumns) {
            const choiceIndex = choices.findIndex(({id}) => id === choiceId)
            const {purchase, retail} = paxId ? choices[choiceIndex].columns.find(c => c.key === groupId + '|' + paxId) :
                choices[choiceIndex].columns.find(({key}) => key.startsWith(groupId))
            const idToFind = makeId(choiceId, groupId, paxId);
            const {retailPrice, retailPriceDefault, manualFactor} = choicesPrices.find(({id}) => id === idToFind)
            const price = retailPrice ?? retailPriceDefault ?? retail

            // @fixme do better validation in core
            if (
                active &&
                ("number" !== typeof purchase || ("number" !== typeof price && "string" !== typeof price)) &&
                ('00001-00001' !== paxId && !/^SGL/.test(choices[choiceIndex].shortLabel))
            ) {
                flash.warning("Aktive Kalkulation kann nicht gespeichert werden, " +
                    "da es nicht für den kompletten Geltungsbereich Einkaufs- und Verkaufspreise gibt.")
                return
            }

            const value = retailPrice || null === retailPrice ? {retailPrice} :
                manualFactor || null === manualFactor ? {manualFactor} :
                    undefined

            if (value) {
                const overrideData = params.occupancyChoices[choiceIndex].overrideData
                params.occupancyChoices[choiceIndex] = {
                    ...params.occupancyChoices[choiceIndex],
                    overrideData: {
                        ...overrideData,
                        [groupId]: !paxId ? value : {
                            ...overrideData?.[groupId],
                            [paxId]: value
                        }
                    }
                }
            }
        }

        processFlightOverride(params)
        save(params)
    }
}
