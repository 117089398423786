import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import DebouncedInput from "./debounced-input";
import {useField} from "../store/fields-slice";
import Offer from "../../../order/offer-configuration/offer";
import moment from "moment-timezone";
import {selectAllChoices, useChoiceColumns} from "../store/choices-slice";
import {createSelector} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";

export default function Configurator() {

    const dateRanges = useField("dateRanges");
    const occupancyChoices = useSelector(selectAllChoices);
    const fromDate = dateRanges?.[0]?.fromDate;
    const marginParticipants = useField("marginParticipants")
    const id = useField("id")
    const daysCount = useField("daysCount")

    let b2bGroups = [];

    for (let occupancyChoice of occupancyChoices) {
        b2bGroups.push({
            title: occupancyChoice.shortLabel,
            tempB2bGroupMatchingCode: occupancyChoice.id,
        });
    }

    const calculationOrder = {
        id: id,
        modelId: 'tourism-journey-calculation/calculation',
        b2bParticipants: marginParticipants,
        offerStartDate: fromDate,
        offerEndDate: fromDate ? moment(fromDate).add(daysCount - 1, "day") : null,
        b2bGroups: b2bGroups
    };

    return (
        <div className="col-xs-12">
            <Offer order={calculationOrder} />
        </div>
    );
}
