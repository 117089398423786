import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {addDate, removeDate, setDate} from "./dates-slice";
import {loadCalculation} from "./load-calculation";
import updateDetails from "./update-details";
import {calculationSettingsLoaded} from "./calculation-settings-loaded";

const pricesAdapter = createEntityAdapter()

function updateExtrema(state) {
    const getPrice = id => state?.entities?.[id]?.price ?? state?.entities?.[id]?.defined

    for (const choice of state.choices) {
        const choiceIds = state.ids.filter(id => id.split('|')[1] === choice)
        const lowest = choiceIds.reduce(
            (lowest, current) => getPrice(current) < getPrice(lowest) ? current : lowest,
            choiceIds[0]
        )
        const highest = choiceIds.reduce(
            (highest, current) => getPrice(current) > getPrice(highest) ? current : highest,
            choiceIds[0]
        )
        const lowestPrice = getPrice(lowest)
        const highestPrice = getPrice(highest)
        pricesAdapter.updateMany(
            state,
            choiceIds.map(id => ({id, changes: {
                lowest: getPrice(id) === lowestPrice,
                highest: getPrice(id) === highestPrice
            }}))
        )
    }
}

export const pricesSlice = createSlice({
    name: "calculation/dates-prices",
    initialState: pricesAdapter.getInitialState({flightPrices: false}),
    reducers: {
        setPrice: {
            prepare: (dateId, choiceId, price) => ({payload: {dateId, choiceId, price}, meta: {dirty: true}}),
            reducer: (state, {payload: {dateId, choiceId, price}}) => {
                const id = dateId + "|" + choiceId
                const purchase = state.entities[id].purchase
                pricesAdapter.updateOne(state, {id, changes: {
                    price,
                    margin: purchase ? price - purchase : undefined
                }})
                updateExtrema(state)
            }
        }
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload: {occupancyChoices, dates = []}}) => {
            state.choices = state.flightPrices ?
                [].concat(...occupancyChoices.map(({id}) => [id, id + "-noflights"])) :
                occupancyChoices.map(({id}) => id)
            pricesAdapter.setAll(
                state,
                [].concat(
                    ...dates.map(({id, date, prices = {}}) => state.choices.map(choiceId => ({
                        id: id + '|' + choiceId,
                        date: date.substring(0, 10),
                        price: prices[choiceId]
                    })))
                )
            )
            updateExtrema(state)
        })
        .addCase(updateDetails, (state, {payload: {dates}}) => {
            pricesAdapter.updateMany(
                state,
                state.ids.map(id => {
                    const [purchase, defined] = dates[state.entities[id].date]?.[id.split('|')[1]] ?? []
                    const price = state.entities[id].price ?? defined
                    return ({
                        id, changes: {
                            defined,
                            purchase,
                            margin: price && purchase ? price - purchase : undefined
                        }
                    })
                })
            )
            updateExtrema(state)
        })
        .addCase(removeDate, (state, {payload: dateId}) => {
            pricesAdapter.removeMany(state, state.ids.filter(id => id.split('|')[0] === dateId))
        })
        .addCase(addDate, (state, {payload: {id, date}}) => {
            pricesAdapter.addMany(
                state,
                state.choices.map(choiceId => ({id: id + '|' + choiceId, date}))
            )
        })
        .addCase(setDate, (state, {payload: {id, date}}) => {
            pricesAdapter.updateMany(
                state,
                state.ids.filter(i => i.split('|')[0] === id).map(id => ({id, changes: {date}}))
            )
        })
        .addCase(calculationSettingsLoaded, (state, {
            payload: {
                flightGroup
            }
        }) => {
            state.flightPrices = flightGroup && "" !== flightGroup
        })
})

export const datesPrices = pricesSlice.reducer
export default datesPrices

export const {setPrice} = pricesSlice.actions

export const {
    selectAll: selectAllPrices,
    selectById: selectPriceById,
} = pricesAdapter.getSelectors(state => state.calculation.datesPrices)

export const useDatePrice = (id, choiceId) => useSelector(state => selectPriceById(state, id + '|' + choiceId))
