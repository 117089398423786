import React from "react"

export class ErrorBoundary extends React.Component {
    state = {
        error: null,
        errorInfo: null
    }

    static getDerivedStateFromError(error) {
        return {error}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error, errorInfo})
    }

    render = () => this.state.error || this.state.errorInfo ? (
        <div className="alert alert-danger">
            <h3>
                <span className="fa fa-circle-exclamation"></span>
                {" "}
                <strong>Ein Fehler ist aufgetreten</strong>
                <br/>
                <small>Sollte sich dies wiederholen, wenden Sie sich bitte an den Support.</small>
            </h3>

            <details style={{whiteSpace: "pre-wrap", marginTop:"1rem"}}>
                {this.state.error && this.state.error.toString()}
                <br/>
                {this.state.errorInfo?.componentStack}
            </details>
        </div>
    ) : this.props.children
}
