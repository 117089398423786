import {createEntityAdapter, createSelector, createSlice, nanoid} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {setField} from "./fields-slice";
import {calculationDetailsLoaded} from "../../../store/api";
import {loadCalculation} from "./load-calculation";
import updateDetails from "./update-details";

/**
 * @typedef {from: string, to: string, color: string, label: string} Season
 */

const datesAdapter = createEntityAdapter({
    sortComparer: ({date: a}, {date: b}) => a.localeCompare(b)
})

const datesSlice = createSlice({
    name: "calculation/dates",
    initialState: datesAdapter.getInitialState({seasons: {}}),
    reducers: {
        addDate: {
            prepare: date => ({meta: {dirty: true}, payload: {id: nanoid(), date: date.substring(0, 10)}}),
            reducer: (state, {payload: {id, date}}) => {
                datesAdapter.addOne(state, {id, date})
            }
        },
        setDate: {
            prepare: (id, date) => ({payload: {id, date: date.substring(0, 10)}, meta: {dirty: true}}),
            reducer: (state, {payload: {id, date}}) => {
                datesAdapter.setOne(state, {id, date: date?.substr(0, 10)})
            }
        },
        removeDate: {
            prepare: id => ({payload: id, meta: {dirty: true}}),
            reducer: (state, {payload: id}) => {
                datesAdapter.removeOne(state, id)
            }
        }
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload: {fromDate, dates = []}}) => {
            datesAdapter.setAll(state, dates.map(({id, date}) => ({id, date: date?.substr(0, 10)})))
            state.min = fromDate?.substr(0, 10)
        })
        .addCase(updateDetails, (state, {payload: {dates, seasons}}) => {
            state.seasons = Array.isArray(seasons) ? {} : (seasons ?? {})
            datesAdapter.updateMany(
                state,
                state.ids.map(id => {
                    const {season, end, warning} = dates[state.entities[id].date] ?? {}
                    return {id, changes: {season, end, warning}}
                })
            )
        })
        .addCase(setField, (state, {payload: {field, value}}) => {
            switch (field) {
                case "fromDate":
                    state.min = value.substr(0, 10)
                    for (const id of state.ids) {
                        if (state.entities[id].date < state.min) {
                            state.entities[id].date = state.min
                        }
                    }
                    break

                case "toDate":
                    const max = value.substr(0, 10)
                    for (const id of state.ids) {
                        if (state.entities[id].date > max) {
                            state.entities[id].date = max
                        }
                    }
                    break
            }
        })
})


export const dates = datesSlice.reducer
export default dates

export const {
    selectAll: selectAllDates,
    selectById: selectDateById,
    selectIds: selectDateIds,
} = datesAdapter.getSelectors(state => state.calculation.dates)

export const {addDate, setDate, removeDate} = datesSlice.actions

const selectSeasons = createSelector(
    state => state.calculation.dates.seasons,
    seasons => seasons
)
const selectSeasonById = id => createSelector(
    selectSeasons,
    seasons => seasons[id]
)

export const useDate = id => useSelector(state => selectDateById(state, id))
/** @type {() => Season[]} */
export const useSeasons = () => Array.from(Object.values(useSelector(selectSeasons)))
/** @type {(id: string) => Season} */
export const useSeason = id => useSelector(selectSeasonById(id))

