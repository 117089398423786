import React, {useContext, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button} from "rsuite";
import cs from "classnames";
import Help from "./help";
import CalculationContext from "../context";
import {useField} from "../store/fields-slice";
import PriceDisplay from "./price-display";
import DebouncedInput from "./debounced-input";
import {makeId, selectAllChoicesPrices, setChoicePriceField, useChoicePrice} from "../store/choices-prices-slice";
import {useColSpan, useColumnCount, useColumnKeys, useHideOccupancyChoices} from "../store/state-slice";

import {
    setChoiceField,
    useChoice,
    useChoiceColumns,
    useChoiceLabel,
    useChoiceSettings,
    useMarginFractionTitle,
    useOverrideColumns
} from "../store/choices-slice";

const purchaseSelector = ({purchase}) => purchase
const retailSelector = ({retail}) => retail
const marginSelector = ({margin}) => margin

export default function CalculationTableFooterPrices({choiceId}) {
    const {hideCalculatedPurchasePrice, showSingleRoomUpcharge, showPreviousPriceField, activateTotalMargin} = useChoiceSettings()
    const hideOccupancyChoices = useHideOccupancyChoices()
    const colSpan = useColSpan()
    const choiceColumns = useChoiceColumns()
    const columnCount = useColumnCount() + (hideOccupancyChoices ? -1 : choiceColumns)
    const choiceLabel = useChoiceLabel(choiceId)
    const [show, setShow] = useState(true)
    const rows = []

    rows.push(
        <DisplayRow
            key="purchaseSum"
            title="EK gesamt"
            help="tourism-journey-calculation.purchase-price-sum-help"
            choiceId={choiceId}
            amountSelector={purchaseSelector}
        />
    )

    if (!hideCalculatedPurchasePrice) {
        rows.push(
            <DisplayRow
                key="retailSum"
                title="VK gesamt"
                help="tourism-journey-calculation.retail-price-sum-help"
                choiceId={choiceId}
                amountSelector={retailSelector}
            />
        )
        rows.push(
            <DisplayRow
                key="factors"
                title="Marge / DB"
                help="tourism-journey-calculation.margin-help"
                choiceId={choiceId}
                percentageSelector={marginSelector}
                color
            />
        )
    }

    rows.push(
        <InputRow
            key="retailPrices"
            title={hideCalculatedPurchasePrice ? "VK" : "VK überschreiben"}
            help="tourism-journey-calculation.overwrite-help"
            field="retailPrice"
            choiceId={choiceId}
        />
    )

    rows.push(
        <InputRow
            key="manualFactors"
            title="DB überschreiben"
            field="manualFactor"
            choiceId={choiceId}
        />
    )

    rows.push(
        <MarginFractionRows key="marginFractions" choiceId={choiceId}/>
    )

    if (activateTotalMargin) {
        rows.push(
            <MarginTotalRows key="marginTotalRows" choiceId={choiceId}/>
        )
    }

    rows.push(
        <ChildPrices key="childPrices" choiceId={choiceId}/>
    )

    if (showSingleRoomUpcharge) {
        rows.push(
            <SingleRoomUpcharge key="singleRoomUpcharge" choiceId={choiceId}/>
        )
    }

    if (showPreviousPriceField) {
        rows.push(
            <PreviousPricesRow key="previousPrices" choiceId={choiceId}/>
        )
    }

    return (
        <>
            <tr className="group">
                <th>
                    <Button appearance="link" size="xs" onClick={() => setShow(!show)} style={{color: "black"}}>
                        <span className={"fa fa-" + (show ? "minus" : "plus")}/>
                    </Button>
                </th>
                <th colSpan={colSpan} className="sticky">{choiceLabel}</th>
                <th colSpan={columnCount}/>
            </tr>
            {show && rows}
        </>
    )
}

function FooterRow({title, help, children}) {
    const {i18n} = useContext(CalculationContext)
    const {colSpan} = useChoiceSettings()
    const choiceColumns = useChoiceColumns()
    const hideOccupancyChoices = useHideOccupancyChoices()

    return (
        <tr>
            <th className="text-right border-right" colSpan={colSpan}>
                {title.includes(" ") ? title : i18n.tr(title)}
                {help && (
                    <>
                        {" "}
                        <Help>{i18n.tr(help)}</Help>
                    </>
                )}
            </th>
            {!hideOccupancyChoices && <td className="border-right" colSpan={choiceColumns}/>}
            {children}
        </tr>
    )
}

function DisplayRow({choiceId, color, amountSelector, percentageSelector, ...props}) {
    const {currencyConverter, percentageConverter} = useContext(CalculationContext)
    const currency = useField("currency")
    const marginParticipants = useField("marginParticipants")
    const {updating, columns} = useChoice(choiceId)

    return (
        <FooterRow {...props}>
            {columns.map(cell => {
                const {key, from, to, border} = cell
                let amount = (amountSelector ? amountSelector(cell) : null) ?? null
                const percentage = (percentageSelector ? percentageSelector(cell) : null) ?? null

                return (
                    <td key={key} className={cs("text-right", "pax", cell.color, {
                        "border-right": border,
                        "margin-pax": from <= marginParticipants && marginParticipants <= to,
                        "margin-tax": percentage && color && cell.color,
                        [cell.color]: percentage && color && cell.color
                    })}>
                        {amountSelector ? (
                            <>
                                {"number" === typeof amount && (
                                    <PriceDisplay
                                        number
                                        money={isNaN(amount) ? null : {amount, currency}}
                                        currencyConverter={currencyConverter}
                                        updating={updating}
                                    />
                                )}

                                {null !== percentage && (
                                    <em><br/>{percentageConverter.toView(percentage)}</em>
                                )}
                            </>
                        ) : "number" === typeof percentage ? (
                            percentageConverter.toView(percentage)
                        ) : null}
                    </td>
                )
            })}
        </FooterRow>
    )
}

function InputRow({choiceId, field, ...props}) {
    const overrideColumns = useOverrideColumns()
    const marginParticipants = useField("marginParticipants")

    return (
        <FooterRow {...props}>
            {overrideColumns.map(({groupId, paxId, colSpan, border, marginPax, from, to}) => (
                <td key={groupId + paxId} colSpan={colSpan} className={cs("text-right", "pax", {
                    "border-right": border,
                    "margin-pax": marginPax || (from <= marginParticipants && marginParticipants <= to)
                })}>
                    <InputCell choiceId={choiceId} groupId={groupId} paxId={paxId} field={field}/>
                </td>
            ))}
        </FooterRow>
    )
}

function InputCell({choiceId, groupId, paxId, field}) {
    const dispatch = useDispatch()
    const {updating} = useChoice(choiceId)
    const choicePrice = useChoicePrice(choiceId, groupId, paxId)

    return (
        <DebouncedInput
            throttle={2000}
            type="number"
            step="0.01"
            style={{maxWidth: "100%"}}
            onChange={onChange}
            disabled={updating}
            value={choicePrice[field] ?? choicePrice[field + "Default"] ?? ""}
        />
    )

    function onChange(value) {
        dispatch(setChoicePriceField(field, choiceId, groupId, paxId, value))
    }
}

function MarginFractionRows({choiceId}) {
    const {marginFractions} = useChoice(choiceId)

    return marginFractions.map(fraction => (
        <MarginFractionRow key={fraction} choiceId={choiceId} fraction={fraction}/>
    ))
}

function MarginFractionRow({choiceId, fraction}) {
    const title = useMarginFractionTitle(fraction)

    return (
        <DisplayRow
            key={fraction}
            choiceId={choiceId}
            color={"___sum" === fraction}
            title={title}
            percentageSelector={cell => cell.marginFractions?.[fraction]?.[0]}
            amountSelector={cell => cell.marginFractions?.[fraction]?.[1]}
        />
    )
}


function MarginTotalRows({choiceId}) {
    const {marginFractions} = useChoice(choiceId)

    return marginFractions.map(fraction => (
        <MarginTotalRow key={fraction} choiceId={choiceId} fraction={fraction}/>
    ))
}

function MarginTotalRow({choiceId, fraction}) {
    return (
        <DisplayRow
            key={fraction}
            choiceId={choiceId}
            color={"___sum" === fraction}
            title="Gesamtmarge"
            help="tourism-journey-calculation.total-margin-help"
            percentageSelector={cell => cell.marginFractions?.[fraction]?.[0]}
            amountSelector={cell => cell.marginFractions?.[fraction]?.[1] * cell.from ?? 0}
        />
    )
}

function SingleRoomUpcharge({choiceId}) {
    const {columns} = useChoice(choiceId)

    if (!columns.find(({upcharge}) => upcharge)) {
        return null
    }

    return (
        <DisplayRow
            choiceId={choiceId}
            title="Aufpreis"
            amountSelector={cell => cell.upcharge}
        />
    )
}

function ChildPrices({choiceId}) {
    const {childPrices = []} = useChoice(choiceId) ?? {}

    return childPrices.map(([id, label]) => (
        <React.Fragment key={id}>
            <DisplayRow
                choiceId={choiceId}
                title={"Preisnachlass " + label}
                amountSelector={cell => -1 * cell.childPrices?.[id]}
            />
            <DisplayRow
                choiceId={choiceId}
                title={"VK " + label}
                amountSelector={cell => {
                    const retail = cell.overrideRetail ?? cell.retail
                    return (
                        "number" === typeof retail && "number" === typeof cell.childPrices?.[id] ?
                            retail - cell.childPrices[id] : null
                    )
                }}
            />
        </React.Fragment>
    ))
}

function PreviousPricesRow({choiceId}) {
    const dispatch = useDispatch()
    const {percentageConverter} = useContext(CalculationContext)
    const occupancyCount = useChoiceColumns()
    const hideOccupancyChoices = useHideOccupancyChoices()
    const choicesPrices = useSelector(selectAllChoicesPrices)
    const colSpan = useColSpan()
    const columnKeys = useColumnKeys()
    const marginParticipants = useField("marginParticipants")
    let {previousPrice, retail} = useChoice(choiceId)

    if (!columnKeys.length) {
        return null
    }

    previousPrice = "" === previousPrice ? false : parseFloat(previousPrice)

    return (
        <tr>
            <th className="text-right border-right" colSpan={colSpan}>
                Preis Vorjahr<br/>
                <DebouncedInput
                    throttle={200}
                    type="number"
                    step="0.01"
                    value={previousPrice}
                    onChange={onPreviousPriceChange}
                />
            </th>

            {!hideOccupancyChoices && <td className="border-right" colSpan={occupancyCount}/>}

            {columnKeys.map(({groupId, paxId, border, from, to}) => {
                const possibleIds = [makeId(choiceId, groupId, paxId), makeId(choiceId, groupId)]
                const choicesPrice = choicesPrices.find(({id}) => possibleIds.includes(id))
                const price = choicesPrice?.retailPrice ?? choicesPrice?.retailPriceDefault ?? retail ?? false

                return (
                    <td key={groupId + paxId} className={cs("text-right", "pax", {
                        "border-right": border,
                        "margin-pax": from <= marginParticipants && marginParticipants <= to
                    })}>
                        {!price || !previousPrice ? null :
                            percentageConverter.toView(parseFloat(price) / previousPrice - 1, '+0.0%')}
                    </td>
                )
            })}
        </tr>
    )

    function onPreviousPriceChange(value) {
        dispatch(setChoiceField(choiceId, "previousPrice", value))
    }
}
