export function processFlightOverride(params) {
    const filter = /-noflights$/
    const flightChoices = params.occupancyChoices.filter(({id}) => filter.test(id))

    if (flightChoices.length) {
        const choices = new Map(
            params.occupancyChoices
                .filter(({id}) => !filter.test(id))
                .map(({id, ...rest}) => [id, {id, ...rest}])
        )

        for (const {id, overrideData} of flightChoices) {
            choices.get(id.replace(filter, "")).overrideDataNoFlights = overrideData
        }

        params.occupancyChoices = Array.from(choices.values())
    }
}
