import React, {useEffect, useState} from "react";
import {Loader, Placeholder} from "rsuite";

export default function PriceDisplay({money, number, updating, currencyConverter}) {
    const [cachedMoney, setCachedMoney] = useState()

    useEffect(
        () => {
            if (cachedMoney?.amount !== money?.amount || cachedMoney?.currency !== money?.currency) {
                setCachedMoney(money)
            }
        },
        [money?.amount, money?.currency]
    )

    if (updating && !cachedMoney) {
        return (
            <Placeholder.Paragraph rows={1} active/>
        )
    }

    let value = (
        <span className="label label-info">kein EK</span>
    )

    const zeroValue = (
        <span className="label label-info">&mdash;</span>
    )


    if ("number" === typeof (cachedMoney?.amount ?? money?.amount)) {
        try {
            value = currencyConverter.toView("number" === typeof cachedMoney?.amount ? cachedMoney : money, number ? "number" : undefined)
        } catch (error) {
            console.error({error, cachedMoney, money})
            value = (
                <span className="label label-info">{"" + error}</span>
            )
        }
    }
    if ("number" !== typeof (cachedMoney?.amount ?? money?.amount)) {
        value = zeroValue;
    }

    return (
        <>
            {updating && <Loader/>}
            {value}
        </>
    )
}
