import {bindable, observable, customElement, inject, LogManager} from 'aurelia-framework';
import {Client} from '../../api/client';
import * as $ from 'jquery';
import {DialogController} from "aurelia-dialog";
import {EventAggregator} from "aurelia-event-aggregator";

const logger = LogManager.getLogger('PnrSearch');

import "./pnr-search.less";

@customElement('pnr-search')
@inject(Client, DialogController, EventAggregator)
export class PnrSearch {
    @bindable contextObjectRef;

    params;
    result;
    error;

    searching = false;
    adding = false;

    initialized = false;
    @observable flightProvider = null;
    flightProviders = [];

    client;
    dialog;
    ea;

    constructor(client, dialog, ea) {
        this.client = client;
        this.dialog = dialog;
        this.ea = ea;
    }

    bind() {
        this.params = {};
        this.error = null;

        this.client.get('tourism-flight/pnr/services')
            .then((flightProviders) => {
                this.flightProviders = flightProviders;

                if (this.flightProviders && this.flightProviders.length > 0) {
                    this.flightProvider = this.flightProviders[0].value;
                } else {
                    this.error = 'Keine Fluganbieter verfügbar';
                }

                this.initialized = true;
            }, (error) => {
                logger.error(error);
                this.error = error;

                this.initialized = true;
            });
    }

    search() {
        this.error = null;
        this.searching = true;

        this.client.get(`tourism-flight/pnr/${this.flightProvider}/search?` + $.param(this.params)).then(
            result => {
                this.result = result;
            },
            error => {
                logger.error(error);

                if (error.data?.localizedMessage) {
                    this.error = error.data?.localizedMessage;
                } else if (error.data?.message) {
                    this.error = error.data?.message;
                } else {
                    this.error = 'Ein Fehler ist aufgetreten. Probieren Sie es später erneut oder wenden Sie sich an den Support.';
                }
            }
        ).finally(() => {
            this.searching = false;
        });
    }

    flightProviderChanged() {
        this.params = {};
        this.error = null;
    }

    select(newParams) {
        this.params = newParams;
        this.search();
    }

    add() {
        this.adding = true;

        this.client.put(
            `tourism-flight/pnr/${this.flightProvider}/${this.params.recordLocator}`,
            {contextObj: this.contextObjectRef}
        ).then(
            result => {
                this.ea.publish(
                    'sio_form_post_submit',
                    {config: {modelId: 'tourism-flight/itinerary'}}
                );

                if (this.contextObjectRef) {
                    this.ea.publish(
                        'sio_form_post_submit',
                        {config: {modelId: this.contextObjectRef.modelId}}
                    );
                }

                this.dialog.ok();
            },
            error => {
                logger.error(error);

                if (error.data?.localizedMessage) {
                    this.error = error.data?.localizedMessage;
                } else if (error.data?.message) {
                    this.error = error.data?.message;
                } else {
                    this.error = 'Ein Fehler ist aufgetreten. Probieren Sie es später erneut oder wenden Sie sich an den Support.';
                }
            }
        ).finally(() => {
            this.adding = false;
        });
    }
}
