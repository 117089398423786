import { bindable, customElement, inject } from "aurelia-framework";

import { NumberValueConverter } from "../../converters";

@customElement("holiday-check-rating")
@inject(NumberValueConverter)
export class HolidayCheck {
    @bindable rating;

    constructor(number) {
        this.number = number;
    }

    getPopoverContent(rating) {
        return (
            '<div><dl class="row" style="margin-bottom: 0;">' +
            '<dt class="col-xs-3">Gesamt</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.averageRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Hotel</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.hotelRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Zimmer</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.roomRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Service</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.serviceRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Lage</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.locationRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Gastronomie</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.foodRating, "0.[0]") +
            " von 6</dd>" +
            '<dt class="col-xs-3">Sport & Unterhaltung</dt>' +
            '<dd class="col-xs-3">' +
            this.number.toView(rating?.sportRating, "0.[0]") +
            " von 6</dd>" +
            "</dl></div>"
        );
    }
}
