import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {DialogService} from "aurelia-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import "jquery-sticky";

@customElement('sio-tourism-calculation')
@inject(Client, FlashService, DialogService, EventAggregator)
export class OldCalculation {

    @bindable calculation;

    table = {};
    columns = [];

    constructor(client, flash, dialogService, ea) {
        this.client = client;
        this.flash = flash;
        this.dialogService = dialogService;
        this.ea = ea;

        this.subscription = this.ea.subscribe('sio_form_post_submit', async response => {

            if (response.config.modelId != 'tourism-journey-calculation/calculation-line' && response.config.modelId != 'tourism-journey-calculation/calculation') {
                return;
            }

            await this.bind();
        });
    }

    async bind() {
        let conditions = {
            calculation: {
                $eq: {
                    id: this.calculation.id,
                    modelId: this.calculation.modelId
                }
            }
        };

        let results = await this.client.get('tourism-journey-calculation/calculation-line?conditions=' + JSON.stringify(conditions));

        this.lines = results.items;

        this.table = await this.client.get('tourism-journey-calculation/calculate/' + this.calculation.id);

        this.columns = [];

        for (let i = this.calculation.minParticipants; i <= this.calculation.maxParticipants; i++) {
            this.columns.push(i);
        }
    }

}
