import {inject, bindable, customElement, LogManager} from "aurelia-framework";
import {Client} from "../../api/client";
import {I18N} from 'aurelia-i18n';
import {DialogService} from "aurelia-dialog";
import {EditEquipmentDialog} from "./edit-equipment-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import {FlashService} from "../../flash/flash-service";
import * as _ from "lodash";

import "./manage-equipment.less";

const logger = LogManager.getLogger('ManageEquipment');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@customElement('sio-manage-equipment')
@inject(
    Client,
    I18N,
    DialogService,
    EventAggregator,
    FlashService
)
export class ManageEquipment
{
    @bindable journeyTemplateRef;
    @bindable itineraryRef;
    @bindable travelPackageRef;

    journeyTemplate;
    itinerary;

    availableEquipment;
    selectedEquipment;

    loadingState = {
        journeyTemplate: false,
        itinerary: false,
        availableEquipment: false,
    };

    search = {
        filters: {
            title: '',
            category: ''
        }
    };

    constructor(
        client,
        i18n,
        dialogService,
        ea,
        flash
    ) {
        this.client = client;
        this.i18n = i18n;
        this.dialogService = dialogService;
        this.ea = ea;
        this.flash = flash;
    }

    bind()
    {
        this.preloadAllEquipment();
        this.journeyTemplateRefChanged();
        this.itineraryRefChanged();
        this.travelPackageRefChanged();
    }

    loaded(loadingState)
    {
        for (let property in loadingState) {
            if (!loadingState.hasOwnProperty(property)) {
                continue;
            }
            if (false !== loadingState[property]) {
                return false;
            }
        }

        return true;
    }

    updateLoadingState(stage, state)
    {
        this.loadingState = Object.assign({}, this.loadingState, {[stage]: state});
    }

    journeyTemplateRefChanged()
    {
        if (!this.journeyTemplateRef) {
            this.journeyTemplate = null;
            return;
        }

        this.updateLoadingState('journeyTemplate', true);

        this.client.get('tourism/journey-template/'+this.journeyTemplateRef.id).then(
            response => {
                this.journeyTemplate = response;
                this.selectedEquipment = this.journeyTemplate.equipment;

                this.updateLoadingState('journeyTemplate', false);
            }
        );
    }

    itineraryRefChanged() {
        if (!this.itineraryRef) {
            this.itinerary = null;
            return;
        }

        this.updateLoadingState('itinerary', true);

        this.client.get('tourism/itinerary/' + this.itineraryRef.id).then(
            response => {
                this.itinerary = response;
                this.selectedEquipment = this.itinerary.equipment;

                this.updateLoadingState('itinerary', false);
            }
        );
    }

    travelPackageRefChanged() {
        if (!this.travelPackageRef) {
            this.travelPackageRef = null;
            return;
        }

        this.updateLoadingState('itinerary', true);

        this.client.get('tourism-travel-package/travel-package/' + this.travelPackageRef.id).then(
            response => {
                this.itinerary = response;
                this.selectedEquipment = this.itinerary.equipment;

                this.updateLoadingState('itinerary', false);
            }
        );
    }

    preloadAllEquipment()
    {
        this.updateLoadingState('availableEquipment', true);

        this.client.get('tourism-journey-equipment/equipment?limit=-1&embeds[]=category&sort[0][0]=title&sort[0][1]=ASC').then(
            response => {
                this.availableEquipment = response.items;

                this.updateLoadingState('availableEquipment', false);
            }
        );
    }

    /*private*/ getEquipmentCategories(equipmentArray)
    {
        if (!equipmentArray) {
            return [];
        }

        const categories = [];

        equipmentArray.forEach(eq => {
            let categoryTitle = eq.category ? eq.category.title : 'Keine Kategorie';
            let categorySort = eq.category ? eq.category.sort : 0;
            if (!categories.some(category => category.categoryTitle === categoryTitle)) {
                categories.push({categoryTitle, categorySort});
            }
        });

        return categories;
    }

    /*private*/ sliceSelectedEquipmentByCategories(selectedEquipment, availableEquipment)
    {
        if (!selectedEquipment || !availableEquipment) {
            return [];
        }

        const extendedSelectedEquipment = selectedEquipment.map(eq => {
            return this.embedAdditionalInformationIntoEquipment(eq, availableEquipment)
        });

        return this.sliceAvailableEquipmentByCategories(extendedSelectedEquipment);
    }

    /*private*/ sliceAvailableEquipmentByCategories(availableEquipment)
    {
        if (!availableEquipment) {
            return [];
        }

        const equipmentByCategory = [];

        const categories = this.getEquipmentCategories(availableEquipment);
        categories.forEach(categoryElement => {
            const category = {
                name: categoryElement.categoryTitle,
                sort: categoryElement.categorySort,
                items: availableEquipment.filter(eq => {

                    let categoryTitle = eq.category ? eq.category.title : 'Keine Kategorie';

                    return categoryTitle === categoryElement.categoryTitle;
                })
            };

            equipmentByCategory.push(category);
        });

        equipmentByCategory.sort((a,b) => a.sort - b.sort)

        return equipmentByCategory;
    }

    /*private*/ embedAdditionalInformationIntoEquipment(eq, availableEquipment)
    {
        const eqFull = this.findAvailableEquipmentById(eq.equipment.id, availableEquipment);

        return Object.assign({}, eqFull, {
            originalEntity: eq
        });
    }

    /*private*/ findSelectedEquipmentById(id, selectedEquipment)
    {
        if (!selectedEquipment) {
            return undefined;
        }

        const eq = selectedEquipment.filter(eq => eq.equipment.id === id);
        return eq.length === 0 ? undefined : eq[0];
    }

    /*private*/ findAvailableEquipmentById(id, availableEquipment)
    {
        if (!availableEquipment) {
            return undefined;
        }

        const eq = availableEquipment.filter(eq => eq.id === id);
        return eq.length === 0 ? undefined : eq[0];
    }

    extendedEquipmentTitle(eq)
    {
        let title = eq.title;

        if (eq.originalEntity && eq.originalEntity.remark) {
            title += ' ('+eq.originalEntity.remark+')';
        }

        return title;
    }

    trCategoryName(categoryName)
    {
        if (!categoryName) {
            return '<Keine Kategorie>';
        }

        return categoryName;
    }

    applySearchFilters(equipmentByCategory, filters)
    {
        if (filters.category) {
            equipmentByCategory = equipmentByCategory.filter(category => {
                return this.trCategoryName(category.name).toLowerCase().indexOf(filters.category.toLowerCase()) !== -1;
            });
        }

        if (filters.title) {
            equipmentByCategory.forEach(category => {
                category.items = category.items.filter(item => {
                    return this.extendedEquipmentTitle(item).toLowerCase().indexOf(filters.title.toLowerCase()) !== -1;
                });
            });
        }

        return equipmentByCategory;
    }

    onlyNotSelectedEquipment(equipmentByCategory, selectedEquipment)
    {
        equipmentByCategory.forEach(category => {
            category.items = category.items.filter(eq => {
                return undefined === this.findSelectedEquipmentById(eq.id, selectedEquipment);
            });
        });

        return equipmentByCategory;
    }

    removeEmptyCategoriesFromResults(equipmentByCategory)
    {
        return equipmentByCategory.filter(category => {
            return category.items.length > 0;
        });
    }

    filterSelectedEquipment(selectedEquipment, availableEquipment, filters)
    {
        let filteredSelectedEquipment = this.removeEmptyCategoriesFromResults(
            this.applySearchFilters(
                this.sliceSelectedEquipmentByCategories(selectedEquipment, availableEquipment), filters
            )
        )

        filteredSelectedEquipment.forEach(equipmentCategory => {
            equipmentCategory.items.sort((a,b) => a.title.localeCompare(b.title));
        });

        return filteredSelectedEquipment;
    }

    filterAvailableEquipment(selectedEquipment, availableEquipment, filters)
    {
        let filteredAvailableEquipment = this.removeEmptyCategoriesFromResults(
            this.onlyNotSelectedEquipment(
                this.applySearchFilters(
                    this.sliceAvailableEquipmentByCategories(availableEquipment), filters), selectedEquipment
            )
        )

        filteredAvailableEquipment.forEach(equipmentCategory => {
            equipmentCategory.items.sort((a,b) => a.title.localeCompare(b.title));
        });

        return filteredAvailableEquipment;
    }

    onSearchFiltersChanged(evt, fieldName)
    {
        this.search.filters = Object.assign({}, this.search.filters, {[fieldName]: evt.target.value});
    }

    addToSelection()
    {
        const availableEquipmentIds = _.map(this.availableEquipmentElement.selectedOptions, option => option.value);

        logger.debug('Add equipment to selection', availableEquipmentIds, this.availableEquipment);

        const addSingleEquipment = (availableEquipmentId) => {
            const eq = this.findAvailableEquipmentById(availableEquipmentId, this.availableEquipment);
            this.selectedEquipment = this.selectedEquipment.concat([{
                equipment: {
                    id: eq.id,
                    modelId: eq.modelId
                },
                modelId: 'tourism-journey-equipment/journey-equipment'
            }]);
        };

        availableEquipmentIds.forEach(eq => addSingleEquipment(eq));

        this.availableEquipmentElement.selectedIndex = -1;

        this.notifyAboutChanges();
    }

    removeFromSelection()
    {
        const selectedEquipmentIds = _.map(this.selectedEquipmentElement.selectedOptions, option => option.value);

        logger.debug('Remove equipment from selection', selectedEquipmentIds, this.selectedEquipment);

        const removeSingleEquipment = (selectedEquipmentId) => {
            const eqIndex = this.selectedEquipment.findIndex(eq => eq.equipment.id === selectedEquipmentId);
            this.selectedEquipment.splice(eqIndex, 1);
        };

        selectedEquipmentIds.forEach(eq => removeSingleEquipment(eq));
        this.selectedEquipment = this.selectedEquipment.slice(0);

        this.selectedEquipmentElement.selectedIndex = -1;

        this.notifyAboutChanges();
    }

    editEquipment()
    {
        const selectedEquipmentId = this.selectedEquipmentElement.value;

        if (!selectedEquipmentId) {
            return;
        }

        const selectedEquipment = this.embedAdditionalInformationIntoEquipment(
            this.findSelectedEquipmentById(selectedEquipmentId, this.selectedEquipment),
            this.availableEquipment
        );

        this.showEquipmentEditDialog(selectedEquipment);
    }

    showEquipmentEditDialog(equipment)
    {
        logger.debug('Editor for equipment', equipment);

        return this.dialogService.open({
            viewModel: EditEquipmentDialog,
            model: {
                equipment
            }
        }).whenClosed(response => {
            logger.debug('Dialog controller responded');

            if (response.wasCancelled) {
                return;
            }

            logger.debug('Received values from dialog controller', response, equipment, this.selectedEquipment);
            Object.assign(equipment.originalEntity, response.output);

            this.selectedEquipment = this.selectedEquipment.slice(0);

            this.notifyAboutChanges();
        });
    }

    save()
    {
        this.saving = true;

        if (this.journeyTemplateRef) {
            return this.client
                .put('tourism-journey-equipment/journey-template-equipment/' + this.journeyTemplateRef.id, {
                    equipment: this.selectedEquipment.map(eq => {
                        return {
                            equipment: eq.equipment,
                            remark: eq.remark
                        }
                    })
                })
                .then(
                    response => {
                        this.saving = false;

                        this.flash.success('form.success');
                        this.ea.publish('sio_form_post_submit', {config: {modelId: 'tourism/journey-template'}});

                        this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
                        this.changesKey = null;

                        return response;
                    }, error => {

                        this.saving = false;

                        this.flash.error('form.error');

                        return error;
                    }
                );
        }

        let path = '';
        let model = '';

        if (this.travelPackageRef) {
            path = 'tourism-journey-equipment/travel-package-equipment/' + this.travelPackageRef.id;
            model = 'tourism-travel-package/travel-package';
        } else if (this.itineraryRef) {
            path = 'tourism-journey-equipment/itinerary-equipment/' + this.itineraryRef.id;
            model = 'tourism/itinerary';
        }

        return this.client.put(path, {
                equipment: this.selectedEquipment.map(eq => {
                    return {
                        equipment: eq.equipment,
                        remark: eq.remark
                    };
                })
            })
            .then(
                response => {
                    this.saving = false;

                    this.flash.success('form.success');
                    this.ea.publish('sio_form_post_submit', {config: {modelId: model}});
                    this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
                    this.changesKey = null;

                    return response;
                }, error => {

                    this.saving = false;

                    this.flash.error('form.error');

                    return error;
                }
            );
    }

    notifyAboutChanges()
    {
        if (null != this.changesKey) {
            return; // No need to notify several times
        }

        this.changesKey = 'manage-equipment-'+ new Date();
        this.ea.publish('sio_register_unsaved_changes', {changesKey: this.changesKey});
    }
}
