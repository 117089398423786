import {bindable, customElement, inject} from "aurelia-framework";
import * as _ from "lodash";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {DialogService} from "aurelia-dialog";
import {AddServiceDialog} from "./add-service-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import * as $ from "jquery";
import "jquery-sticky";
import {AddCompetitorDialog} from "./add-competitor-dialog";
import {AddSalesDialog} from "./add-sales-dialog";
import {getSettings} from "../../settings/settings-helpers";

@customElement('tourism-calculation')
@inject(Client, FlashService, DialogService, EventAggregator)
export class Calculation {
    @bindable calculation;

    defaultComparePricesPurchase = [
        {
            pax: '7'
        },
        {
            pax: '8-9'
        },
        {
            pax: '10-11'
        },
        {
            pax: '12-15'
        }
    ];

    defaultComparePricesSale = [
        {
            type: 'Min.'
        },
        {
            type: 'Mittl.'
        },
        {
            type: 'Max.'
        },
        {
            type: 'Silv.'
        }
    ];

    constructor(client, flash, dialogService, ea) {
        this.client = client;
        this.flash = flash;
        this.dialogService = dialogService;
        this.ea = ea;
    }

    setChanged(changed)
    {
        if (changed) {
            if (null != this.changesKey) {
                return; // No need to notify several times
            }

            this.changesKey = 'tourism/calculation__'+ new Date();
            this.ea.publish('sio_register_unsaved_changes', {changesKey: this.changesKey});
            this.ea.publish('sio_disable_actions');
        } else {

            if (this.changesKey != null) {
                this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
                this.ea.publish('sio_enable_actions');
                this.changesKey = null;
            }
        }
    }

    calculationChanged() {
        this.setChanged(false);

        this._calculation = _.cloneDeep(this.calculation);

        this.services = this._processServicesToGroup(this._calculation.calcServices);

        this.sales = this._processSaleToView(this._calculation.sales);

        this.competitors = this._removeModelId(this._calculation.competitors);

        let comparePricesPurchase = this._calculation.comparePricesPurchase;

        if (comparePricesPurchase.length == 0) {
            comparePricesPurchase = _.cloneDeep(this.defaultComparePricesPurchase);
        }

        this.comparePricesPurchase = this._removeModelId(comparePricesPurchase);

        let comparePricesSale = this._calculation.comparePricesSale;

        if (comparePricesSale.length == 0) {
            comparePricesSale = _.cloneDeep(this.defaultComparePricesSale);
        }

        this.comparePricesSale = this._removeModelId(comparePricesSale);

        this.recalculate();
    }

    async bind()
    {
        this.settings = await getSettings('tourism-calculation/calculation', this.client);

        if (!this.settings) {
            this.settings = {};
        }

        this.calculationChanged();
    }

    _fieldChanges()
    {
        this.setChanged(true);

        this.recalculate();
    }

    attached() {
        $('.sticky').sticky({zIndex: 100});
    }

    addService(group) {
        this.dialogService.open({viewModel: AddServiceDialog}).whenClosed(response => {

            if (!response.output) {
                return;
            }

            let output = response.output;
            output.group = group.group;

            this._processServiceDefaults(output);

            group.services.push(output);

        });
    }

    addCompetitor() {
        this.dialogService.open({viewModel: AddCompetitorDialog}).whenClosed(response => {

            if (!response.output) {
                return;
            }

            let output = response.output;

            this.competitors.push(output);

            this.recalculate();
        });
    }

    addSales() {
        this.dialogService.open({viewModel: AddSalesDialog}).whenClosed(response => {

            if (!response.output) {
                return;
            }

            let output = response.output;

            output.percentage = 0;

            this.sales.push(output);

            this.recalculate();
        });
    }

    remove(array, index)
    {
        array.splice(index, 1);

        this.recalculate();
    }

    _processSaleDefaults(sale) {
        if (!sale.percentage) {
            sale.percentage = 0;
        }
    }

    _removeModelId(array) {
        let returnValue = [];

        _.each(array, item => {

            delete item.modelId;

            returnValue.push(item);
        });

        return returnValue;
    }

    _processSaleToView(sales) {
        let returnValue = [];

        _.each(sales, sale => {
            this._processSaleDefaults(sale);

            delete sale.modelId;

            returnValue.push(sale);
        });

        return returnValue;
    }

    _processServiceDefaults(service) {
        if (!service.currencyGroup) {
            service.currencyGroup = 0;
        }

        if (!service.currencyPax) {
            service.currencyPax = 0;
        }

        if (!service.euroGroup) {
            service.euroGroup = 0;
        }

        if (!service.euroPax) {
            service.euroPax = 0;
        }
    }

    _processServicesToGroup(services) {
        let grouped = _.groupBy(services, (v) => {
            return v.group;
        });

        let returnValue = [];

        _.forIn(grouped, (services, key) => {

            let single = false;

            if (key === 'guide') {
                single = true;
            }

            let processServices = [];

            _.each(services, service => {

                this._processServiceDefaults(service);

                delete service.modelId;

                processServices.push(service);
            });

            returnValue.push({
                group: key,
                services: services,
                single: single
            });
        });

        return returnValue;
    }

    _processGroupToServices() {
        let returnValue = [];

        _.each(this.services, (group) => {

            _.each(group.services, (service) => {
                returnValue.push(service);
            });
        });

        return returnValue;
    }

    _processCompetitorsToModel() {
        let returnValue = [];

        _.each(this.competitors, (competitor) => {

            let value = _.cloneDeep(competitor);

            delete value.factor;

            returnValue.push(value);
        });

        return returnValue;
    }

    save(duplicate) {
        let object = _.pick(this._calculation, [
            'exchangeRate',
            'currency',
            'participants',
            'totalServices',
            'totalSales',
            'salePrice',
            'salePriceWithoutSales',
            'salePricePreviousYear',
            'grossMargin',
            'grossMarginAbsolute',
            'currencySingleRoom',
            'euroSingleRoom',
            'salePriceSingleRoom',
            'name',
            'itinerary',
            'journeys',
            'taxMargin',
            'taxMarginEnabled',
            'calculateSalePrice',
            'overwriteSalePriceSingleRoom'
        ]);

        object.calcServices = this._processGroupToServices();
        object.sales = this.sales;
        object.comparePricesSale = this.comparePricesSale;
        object.comparePricesPurchase = this.comparePricesPurchase;
        object.competitors = this._processCompetitorsToModel();

        console.log('data', object);

        let promise = null;

        if (duplicate) {
            promise = this.client.post('tourism-calculation/put-calculation', object);
        } else {
            promise = this.client.put('tourism-calculation/put-calculation/' + this._calculation.id, object);
        }

        return promise.then(response => {

            this.ea.publish('sio_form_post_submit', {config: {modelId: 'tourism-calculation/calculation'}});

            this.flash.success('Erfolgreich gespeichert');

            this.setChanged(false);
        }, response => {

            if (response.status == 403) {
                this.flash.error('sio.access_denied');
            } else {
                this.flash.error('Fehler beim Speichern');
            }
        });
    }

    recalculate() {
        this._calculation.exchangeFactor = 1 / parseFloat(this._calculation.exchangeRate);

        let exchangeFactor = this._calculation.exchangeFactor;

        let totalTotal = 0;

        _.each(this.services, (group) => {

            let total = 0;

            _.each(group.services, (service) => {

                let currencyGroup = this._processNumber(service.currencyGroup);
                let currencyPax = this._processNumber(service.currencyPax);
                let euroGroup = this._processNumber(service.euroGroup);
                let euroPax = this._processNumber(service.euroPax);

                if (service.multiplyByDays) {
                    currencyGroup = currencyGroup * this._calculation.itinerary.daysCount;
                    currencyPax = currencyPax * this._calculation.itinerary.daysCount;
                    euroGroup = euroGroup * this._calculation.itinerary.daysCount;
                    euroPax = euroPax * this._calculation.itinerary.daysCount;
                }

                service.totalGroup = (exchangeFactor * currencyGroup) + euroGroup;
                service.totalPax = (exchangeFactor * currencyPax) + euroPax;

                service.total = service.totalGroup / this._calculation.participants + service.totalPax;

                total = service.total + total;
            });

            group.total = total;

            totalTotal = group.total + totalTotal;
        });

        this._calculation.totalServices = totalTotal;

        if (!this._calculation.calculateSalePrice) {
            let salePrice = this._processNumber(this._calculation.salePrice, true);
            let totalSales = 0;

            _.each(this.sales, (saleItem) => {

                let percentage = this._processNumber(saleItem.percentage);

                saleItem.total = percentage / 100 * salePrice;

                totalSales = saleItem.total + totalSales;
            });

            this._calculation.totalSales = totalSales;

            this._calculation.salePriceWithoutSales = salePrice - totalSales;

            this._calculation.grossMarginAbsolute = (this._calculation.salePriceWithoutSales - this._calculation.totalServices);

            this._calculation.grossMargin = Math.round(this._calculation.grossMarginAbsolute / this._calculation.salePrice * 10000) / 10000;
            this._calculation.grossMarginEntry = this._calculation.grossMargin * 100;
        } else {
            if (!this._calculation.grossMarginEntry) {
                this._calculation.grossMarginEntry = this._calculation.grossMargin * 100;
            }
            let grossMargin = this._processNumber(this._calculation.grossMarginEntry, true);
            this._calculation.grossMargin = grossMargin / 100;

            let salesPercentage = 0;

            _.each(this.sales, (saleItem) => {

                salesPercentage += this._processNumber(saleItem.percentage) / 100;
            });

            //We calculate here the remaining percentage which is for sales
            let servicesPercentage = 1 - this._calculation.grossMargin - salesPercentage;

            this._calculation.salePrice = Math.round(this._calculation.totalServices / servicesPercentage * 100) / 100;

            this._calculation.grossMarginAbsolute = this._calculation.grossMargin * this._calculation.salePrice;

            let totalSales = 0;

            _.each(this.sales, (saleItem) => {

                let percentage = this._processNumber(saleItem.percentage);

                saleItem.total = percentage / 100 * this._calculation.salePrice;

                totalSales = saleItem.total + totalSales;
            });

            this._calculation.totalSales = totalSales;
            this._calculation.salePriceWithoutSales = this._calculation.salePrice - totalSales;

            console.log('services', this._calculation.salePrice);
        }

        this.red = false;

        //Todo check eu
        if (this._calculation.taxMarginEnabled) {
            this._calculation.taxMargin = this._calculation.grossMarginAbsolute * 0.03;

            if (this.settings['gross_margin_threshold_with_tax_margin'] && this._calculation.grossMargin < this.settings['gross_margin_threshold_with_tax_margin']) {
                this.red = true;
            }

        } else {
            this._calculation.taxMargin = null;

            if (this.settings['gross_margin_threshold_without_tax_margin'] && this._calculation.grossMargin < this.settings['gross_margin_threshold_without_tax_margin']) {
                this.red = true;
            }
        }

        if (this._calculation.salePrice && this._calculation.salePricePreviousYear) {
            this._calculation.changePreviousYear = (this._calculation.salePrice - this._calculation.salePricePreviousYear) / this._calculation.salePricePreviousYear;
        } else {
            this._calculation.changePreviousYear = null;
        }

        _.each(this.competitors, (competitor) => {

            let competitorPrice = this._processNumber(competitor.salePrice);

            if (this._calculation.salePrice && competitorPrice) {
                competitor.factor = (this._calculation.salePrice - competitorPrice) / competitorPrice;
            } else {
                competitor.factor = null;
            }

        });

        if (!this._calculation.overwriteSalePriceSingleRoom) {
            let currencySingleRoom = this._processNumber(this._calculation.currencySingleRoom);
            let euroSingleRoom = this._processNumber(this._calculation.euroSingleRoom);

            let totalSingleRoom = (exchangeFactor * currencySingleRoom) + euroSingleRoom;

            this._calculation.salePriceSingleRoom = totalSingleRoom * 1.10;
        }
    }

    _processNumber(field, required = false) {
        if (!required && (isNaN(field) || field == '')) {
            return 0;
        } else if (isNaN(field)) {
            return NaN;
        } else {
            return parseFloat(field);
        }
    }

    calculate(currency, exchangeRate, euro) {
        console.log('calc', currency, exchangeRate, euro);

        return (parseFloat(currency) * parseFloat(exchangeRate)) + parseFloat(euro);
    }
}
