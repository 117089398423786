import {bindable, computedFrom} from "aurelia-framework";

export class Participant {
    @bindable assignment;


    @computedFrom('assignment')
    get participant() {
        if (!this.assignment.participant) {
            return null;
        } else {
            return this.assignment.participant
        }
    }

    @computedFrom('assignment')
    get message() {
        if (!this.assignment.message) {
            return false;
        } else {
            return this.assignment.message;
        }
    }

    @computedFrom('assignment')
    get reservation() {
        if (!this.assignment.reservation) {
            return false;
        } else {
            return this.assignment.reservation;
        }
    }

    @computedFrom('assignment')
    get order() {
        if (!this.assignment.orderItem) {
            return false;
        } else {
            return this.assignment.orderItem.order;
        }
    }
}
