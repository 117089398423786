import {bindable, customElement} from 'aurelia-framework';
import moment from "moment";
import _ from "lodash";

import "./sabre-gmr-display.less";

@customElement('sabre-gmr-display')
export class SabreGmrDisplay {
    @bindable gmr;
    @bindable searchResult;

    text = 'NO DATA';

    gmrChanged() {
        if (!this.gmr) {
            this.text = 'NO DATA';
            return;
        }

        this.text = this.gmr;
    }

    searchResultChanged() {
        if (!this.searchResult) {
            this.text = 'NO DATA';
            return;
        }

        const res = this.searchResult;

        let flights = [];
        for (const flight of this.searchResult.Flight) {
            flights.push(`${flights.length+1} ${flight.CarrierCode || ''}   ${flight.FlightNumber || ''} ${flight.DateOfTravel || ''} ${flight.BoardPoint || ''}${flight.OffPoint || ''}`);
        }

        this.text = `${res.GMRLocator || ''}   ${res.GMRStatus ? `STATUS: ${res.GMRStatus}` : ''}
${res.BlockedSeats ? `BLOCKED-${res.BlockedSeats}` : ''}  ${res.AvailableSeats ? `AVAILABLE-${res.AvailableSeats}` : ''}  ${res.BookedSeats ? `BOOKED-${res.BookedSeats}` : ''}
${flights.join("\n")}

${res.SeatSellStartDate ? `START SELL: ${res.SeatSellStartDate}*` : ''}  ${res.GMRExpiryDate ? `EXPIRATION: ${res.GMRExpiryDate}*` : ''}  ${res.DepositDate ? `DEPOSIT BY: ${res.DepositDate}*` : ''}
${res.SeatReductionDate ? `REDUCTION: ${res.SeatReductionDate}*` : ''}  ${res.SeatReductionPercentage ? `PERCENT: ${this.seatReductionPercentage(res.SeatReductionPercentage)}*` : ''}
`;
    }

    seatReductionPercentage(percentage) {
        return parseInt(percentage).toFixed(0);
    }
}
