import {bindable, observable, customElement} from 'aurelia-framework';

import "./sabre-gmr-search-filters.less";

@customElement('sabre-gmr-search-filters')
export class SabreGmrSearchFilters {
    @bindable params = {};
    @bindable error;

    recordLocator;
    @observable departureDate;
    departureCity;
    arrivalCity;

    paramsChanged() {
        // Update only recordLocator, we don't want to erase already filled-in values from fields
        //  triggered on select() call when user selects a PNR from list of search results.

        this.recordLocator = this.params.recordLocator;
    }

    valueChanged() {
        // this.params serves as output for filter values
        // the inner state of filters is saved inside this.recordLocator, this.lastName and so on.
        //
        // This is done in order to not erase arrivalCity/departureDate filter values when recordLocator is being entered,
        //  and still allow search API call with only recordLocator instead of all fields together.

        if (this.recordLocator) {
            this.params.recordLocator = this.recordLocator;

            delete this.params.departureDate;
            delete this.params.departureCity;
            delete this.params.arrivalCity;
        } else {
            delete this.params.recordLocator;

            this.params.departureDate = this.departureDate;
            this.params.departureCity = this.departureCity;
            this.params.arrivalCity = this.arrivalCity;
        }

        if (!(this.params.departureDate && this.params.departureCity && this.params.arrivalCity) && !this.params.recordLocator) {
            this.error = 'GMR-Kennung oder andere Felder müssen eingegeben werden.';
        }
    }

    departureDateChanged() {
        // Implemented via @observable, because sio-date-input doesn't allow attaching "change.trigger"

        this.valueChanged();
    }
}
